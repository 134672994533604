import ConfirmationModal from "components/Modals/ConfirmationModal";
import Header from "../components/Headers/ConnectionHeader.js";
import React, { useState, useEffect } from "react"
import axios from "axios";
import { Alert, Container, Row, Col, Card, CardHeader, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { generateRandomPassword, copyToClipboard } from "helpers/utils";
import { Spinner } from "reactstrap"
import moment from "moment";
import PhoneInput from 'react-phone-input-2'
import { isValidPhoneNumber } from 'libphonenumber-js'
import 'react-phone-input-2/lib/style.css'
import PermissionsGate from "../components/Permissions/PermissionsGate";
import PERMISSIONS from "../components/Permissions/Permissions";
import { countries } from "helpers/country-codes.js";
import { userManagement_screen } from "../content";

const Users = (props) => {

    const [roles, setRoles] = useState([]);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [isAddUserModalOpen, setAddUserModalOpen] = useState(false);
    const [isEditUserModalOpen, setEditUserModalOpen] = useState(false);
    const [requestLoading, setRequestLoading] = useState(false);
    const [getUsersReqLoading, setGetUsersReqLoading] = useState(false);

    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordCopiedAlert, setShowPasswordCopiedAlert] = useState(false);

    const [userDeleteReqId, setUserDeleteReqId] = useState('');
    const [userEditInfo, setUserEditInfo] = useState({});

    const [userName, setUserName] = useState('');
    const [role, setRole] = useState('');
    const [country, setCountry] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [password, setPassword] = useState('');
    const [phone, setPhone] = useState('');
    const [fullName, setFullName] = useState('');

    const [users, setUsers] = useState([]);

    const getRoles = async () => {
        try {
            const resp = await axios.get(`${process.env.REACT_APP_API_URL}/api/roles`,
                {
                    headers: { 'Authorization': 'Bearer ' + localStorage.getItem('itoken') },
                })
            setRoles(resp.data.roles);
        } catch (err) {
            console.error({ err })
        }
    }

    const getAllUsers = async (filter = {}) => {

        setGetUsersReqLoading(true)

        try {
            const resp = await axios.get(`${process.env.REACT_APP_API_URL}/api/users`,
                {
                    params: {
                        username: filter.name,
                        phone: filter.phone
                    },
                    headers: { 'Authorization': 'Bearer ' + localStorage.getItem('itoken') },
                })
            setGetUsersReqLoading(false)
            setUsers(resp.data.users)
        } catch (err) {
            console.error({ err })
        }
    }

    const deleteUser = (id) => {

        setRequestLoading(true);

        axios.get(`${process.env.REACT_APP_API_URL}/api/user/delete/${id}`,
            {
                headers: { 'Authorization': 'Bearer ' + localStorage.getItem('itoken') },
            }).then((data) => {
                toast.success('User Deleted Successfully');

                getAllUsers()
                setDeleteModalOpen(false)
                setRequestLoading(false);

            }).catch((e) => {

                setRequestLoading(false);
                console.log({ e })

            })

    }

    const addUser = () => {

        if (!validation()) {
            return false;
        }

        setRequestLoading(true);

        let userData = {
            fullName,
            userName,
            phone,
            role,
            country
        }

        userData.password = password
        if (imageUrl) {
            userData.imageUrl = imageUrl
        }

        try {
            axios.post(`${process.env.REACT_APP_API_URL}/api/user/add`, userData,
                {
                    headers: { 'Authorization': 'Bearer ' + localStorage.getItem('itoken') },
                }).then((data) => {

                    toast.success('User Add Successfully');

                    getAllUsers()

                    setAddUserModalOpen(false);

                    setRequestLoading(false);

                    clearData()

                }).catch((e) => {

                    setRequestLoading(false);
                    console.log(e.response.data.error)
                    toast.error(e.response.data.error);

                })

        } catch (error) {
            console.log(error)
            toast.error('Something went wrong!');
            setRequestLoading(false);
        }


    }

    const updateUser = () => {

        if (!validation()) {
            return false;
        }

        setRequestLoading(true);

        let userData = {
            docId: userEditInfo._id,
            fullName,
            userName,
            phone,
            role,
            country
        }

        userData.password = password
        if (imageUrl) {
            userData.imageUrl = imageUrl
        }

        try {
            axios.put(`${process.env.REACT_APP_API_URL}/api/user/update`, userData,
                {
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('itoken')
                    },
                }).then((data) => {

                    toast.success('User Updated Successfully');

                    getAllUsers()

                    setRequestLoading(false);

                    setEditUserModalOpen(false)

                    clearData()

                }).catch((e) => {
                    setRequestLoading(false);
                    console.log(e.response.data.error);
                    toast.error(e.response.data.error);
                })

        } catch (error) {
            console.log(error)
            toast.error('Something went wrong!');
            setRequestLoading(false);
        }

    }

    const setUserEditData = (user) => {

        setUserEditInfo(user)
        setFullName(user.name);
        setUserName(user.username);
        setCountry(user.country)
        setRole(user.role);
        setImageUrl(user.image);
        setPhone(user.phone);
        setEditUserModalOpen(true);
    }

    const clearData = () => {

        setUserEditInfo({})
        setUserName('');
        setCountry('')
        setRole('');
        setImageUrl('');
        setPassword('');
        setPhone('');
        setFullName('');

    }

    const validation = () => {

        var expr = /^[a-zA-Z0-9_]{4,10}$/;

        if (!fullName) {
            toast.error('Full Name Required');
            return false;
        }
        if (!userName) {
            toast.error('UserName Required');
            return false;
        }
        if (!expr.test(userName)) {
            toast.error("Please Enter User Name in Correct Format");
            return false;
        }
        if (!phone) {
            toast.error('Phone No Required');
            return false;
        }
        if (!isValidPhoneNumber('+' + phone)) {
            toast.error('Invalid phone number');
            return false;
        }
        if (!country) {
            toast.error('Country Required');
            return false;
        }
        if (!role) {
            toast.error('Role Required');
            return false;
        }

        if (!isEditUserModalOpen && !password) {
            toast.error('Password Required');
            return false;
        }
        if (!isEditUserModalOpen && password.length < 8) {
            toast.error('Password Must be 8 Char in length');
            return false;
        }
        return true;
    }

    const userSearchByName = (name) => {
        getAllUsers({ name })
    }

    const userSearchByPhone = (phone) => {
        getAllUsers({ phone })
    }

    useEffect(() => {
        getAllUsers();
        getRoles();
    }, [])

    return (
        <div>
            <ToastContainer />

            {/* Delete Confirmation */}
            <ConfirmationModal
                confirmModal={deleteUser}
                id={userDeleteReqId}
                showModal={isDeleteModalOpen}
                hideModal={() => { setDeleteModalOpen(false) }}
                alertClass='alert-danger'
                message={userManagement_screen.confirmationModal_deleteUser_message}
                loading={requestLoading}
            />


            <Header nameSearch={userSearchByName} deepSearch={userSearchByPhone} />

            <Container className="mt--7" fluid id="userManagementContainer">
                <Row>
                    <Col className="order-xl-1">
                        <Card className="bg-secondary shadow">
                            <CardHeader className="bg-white border-0">
                                <Row className="align-items-center">
                                    <Col ms="8" xs="6">
                                        <h3 className="mb-0 d-flex align-items-center">{userManagement_screen.sub_heading}  {getUsersReqLoading && <Spinner className="ml-3" animation="grow" />}</h3>
                                    </Col>
                                    <Col ms="4" xs="6">
                                        <h3 className="mb-0 text-right">
                                            <PermissionsGate hasPermission={[PERMISSIONS.add_user]}>
                                                <button onClick={() => { setAddUserModalOpen(true); clearData() }} className="btn btn-info border-0 text-white ">
                                                    <i class="fas fa-plus-circle"></i> {userManagement_screen.button_add_new_user}
                                                </button>
                                            </PermissionsGate>
                                        </h3>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                {
                                    !getUsersReqLoading && users && users.length == 0 && <img className="w-25 m-auto d-block" src={require("../assets/img/theme/not-found.png").default} />
                                }
                                {users && users.map((user) => (
                                    <Card className="radius-10 border-start border-info-1 shadow">
                                        <CardBody>
                                            <Row className="align-items-center">
                                                <Col md="4">
                                                    <Row className="align-items-center">
                                                        <Col md="6" className="text-center">
                                                            <div className="img-thumbnail img-responsive rounded-circle m-auto d-block user-picture"
                                                                style={{ backgroundImage: `url('${user.image ? user.image : require("../assets/img/theme/no-user.jpg").default}')` }}>

                                                            </div>
                                                        </Col>
                                                        <Col md="6" className="text-center my-xs-3">
                                                            <h3 className="mb-0 text-nowrap">{user.username ? user.username : 'N/A'}</h3>
                                                            <h5 className="text-info text-nowrap">{userManagement_screen.heading_col_username}</h5>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col md="4" xs="7" className="text-center">
                                                    <h3 className="mb-0 text-nowrap">{user.role}</h3>
                                                    <h5 className="text-info text-nowrap">{userManagement_screen.heading_col_role}</h5>
                                                </Col>
                                                <Col md="2" xs="5" >
                                                    <h3 className="mb-0 text-nowrap">{user.createdAt ? moment(user.createdAt).format('YYYY-MM-DD') : 'N/A'}</h3>
                                                    <h5 className="text-info text-nowrap">{userManagement_screen.heading_col_created_date}</h5>
                                                </Col>
                                                <Col md="2" className="d-flex align-items-center justify-content-center my-xs-3 d-block-tablet">

                                                    <PermissionsGate hasPermission={[PERMISSIONS.edit_user]}>
                                                        <button
                                                            onClick={() => {
                                                                setUserEditData(user)
                                                            }}
                                                            className="widgets-icons-2 rounded-circle bg-info border-0 text-white ml-1 mr-1">
                                                            <i class="fas fa-user-edit"></i>
                                                        </button>
                                                    </PermissionsGate>

                                                    <PermissionsGate hasPermission={[PERMISSIONS.delete_user]}>
                                                        <button onClick={() => {
                                                            setUserDeleteReqId(user._id);
                                                            setDeleteModalOpen(true)
                                                        }
                                                        } className="widgets-icons-2 rounded-circle bg-danger border-0 text-white ml-1">
                                                            <i class="fa fa-trash"></i>
                                                        </button>
                                                    </PermissionsGate>

                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                ))}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                {/* Add User Modal */}
                <Modal isOpen={isAddUserModalOpen || isEditUserModalOpen} centered>
                    <ModalHeader closeButton className="shadow mb-3">
                        <h1>{isEditUserModalOpen ? userManagement_screen.modal_addUser_heading_1 : userManagement_screen.modal_addUser_heading_2}</h1>
                    </ModalHeader>
                    <Form id="add-user-form" onSubmit={(e) => { isEditUserModalOpen ? updateUser() : addUser(); e.preventDefault(); }}>
                        <ModalBody className="pt-0 pb-0">
                            <div>
                                <Row>
                                    <Col lg="12">
                                        <FormGroup>
                                            <label className="form-control-label required" htmlFor="input-username">
                                                {userManagement_screen.modal_form_label_fullName}
                                            </label>
                                            <Input
                                                className="form-control-alternative"
                                                id="input-username"
                                                placeholder={userManagement_screen.modal_form_label_fullName}
                                                type="text"
                                                autoComplete="off"
                                                defaultValue={fullName}
                                                onKeyUp={(e) => { setFullName(e.target.value); }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg="12">
                                        <FormGroup>
                                            <label className="form-control-label required" htmlFor="input-username">
                                                {userManagement_screen.modal_form_label_username}
                                            </label>
                                            <Input
                                                className="form-control-alternative text-lowercase"
                                                id="input-username"
                                                placeholder={userManagement_screen.modal_form_label_username}
                                                type="text"
                                                autoComplete="off"
                                                defaultValue={userName}
                                                onKeyUp={(e) => { setUserName(e.target.value); }}
                                            />
                                            <sub className="text-light">{userManagement_screen.modal_form_input_help}</sub>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="12">
                                        <FormGroup>
                                            <label className="form-control-label required" htmlFor="input-username">
                                                {userManagement_screen.modal_form_label_phone}
                                            </label>
                                            <PhoneInput
                                                inputStyle={{ width: "100%" }}
                                                country="mm"
                                                autoFormat={true}
                                                value={phone}
                                                onChange={(e) => {
                                                    setPhone(e)
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg="12">
                                        <FormGroup>
                                            <label className="form-control-label required" htmlFor="input-email">
                                                {userManagement_screen.modal_form_label_country}
                                            </label>

                                            <select className="form-control" onChange={(e) => { setCountry(e.target.value); console.log("e.target.value", e.target.value) }}>
                                                <option value="" disabled selected={isAddUserModalOpen} >{userManagement_screen.modal_form_label_select_country}</option>
                                                {Object.entries(countries).map(([code, name]) => (
                                                    <option selected={country == name} value={code}>{name}</option>
                                                ))}
                                            </select>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="12">
                                        <FormGroup>
                                            <label className="form-control-label required" htmlFor="input-email">
                                                {userManagement_screen.modal_form_label_role}
                                            </label>

                                            <select className="form-control" onChange={(e) => { setRole(e.target.value); }}>
                                                <option value="" disabled selected={isAddUserModalOpen} >{userManagement_screen.modal_form_label_select_role}</option>
                                                {roles && roles.map((item) => (
                                                    <option selected={item.role == role} value={item.role}>{item.role}</option>
                                                ))}
                                            </select>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="12">
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor="input-email">
                                                {userManagement_screen.modal_form_label_permissions}
                                            </label>
                                            <Row>
                                                {role && roles.length > 0 && roles.filter(item => role == item.role)[0]?.permissions.map((item) => {
                                                    return (<Col lg="4" className="pr-0"><li><b style={{ fontSize: 11 }}>{item.replaceAll('can_', '')}</b></li></Col>);
                                                })}
                                            </Row>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="12">
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor="input-username">
                                                {userManagement_screen.modal_form_label_imageURL}
                                            </label>
                                            <Input
                                                className="form-control-alternative"
                                                id="input-username"
                                                placeholder="https://domain.com/user.png"
                                                type="text"
                                                autoComplete="off"
                                                defaultValue={imageUrl}
                                                onKeyUp={(e) => { setImageUrl(e.target.value); }}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col lg="8">
                                        <FormGroup>
                                            <label className="form-control-label required" htmlFor="input-username">
                                                {userManagement_screen.modal_form_label_password}
                                            </label>
                                            <Input
                                                className="form-control-alternative"
                                                id="input-username"
                                                placeholder="*****"
                                                type={showPassword ? "text" : "password"}
                                                autoComplete="off"
                                                defaultValue={password}
                                                onKeyUp={(e) => { setPassword(e.target.value); }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg="4" className="d-flex align-items-center justify-content-between">
                                        <button
                                            onClick={() => {
                                                setShowPassword(!showPassword);
                                            }}
                                            type="button" className="widgets-icons-2 rounded-circle bg-primary border-0 text-white mr-1">
                                            {showPassword ? <i class="fas fa-eye"></i> : <i class="fas fa-eye-slash"></i>}
                                        </button>
                                        <button type="button"
                                            onClick={() => {
                                                setPassword(generateRandomPassword());
                                            }}
                                            className="widgets-icons-2 rounded-circle bg-primary border-0 text-white ml-1 mr-1">
                                            <i class="fas fa-redo"></i>
                                        </button>
                                        <button
                                            onClick={async () => {
                                                await copyToClipboard(password);
                                                setShowPasswordCopiedAlert(true)
                                                setTimeout(() => { setShowPasswordCopiedAlert(false) }, 2000)
                                            }}
                                            type="button" className="widgets-icons-2 rounded-circle bg-primary border-0 text-white ml-1">
                                            <i class="fas fa-copy"></i>
                                        </button>
                                    </Col>

                                    <Col lg="12">
                                        <Alert isOpen={showPasswordCopiedAlert} color="success" className="pt-1 pb-1 text-center">
                                            {userManagement_screen.modal_form_alert_message}
                                        </Alert>
                                    </Col>
                                </Row>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="danger" onClick={() => { setAddUserModalOpen(false); setEditUserModalOpen(false) }}>
                                {userManagement_screen.button_cancel}
                            </Button>
                            <Button type="submit" color="primary" disabled={requestLoading}>
                                {isEditUserModalOpen ? userManagement_screen.button_update : userManagement_screen.button_add} {requestLoading && <Spinner animation="grow" size={'sm'} />}
                            </Button>
                        </ModalFooter>
                    </Form>
                </Modal>
            </Container>
        </div >
    )
}

export default Users;