
import React, { useState } from "react";

// reactstrap components
import { Button, Container, Row, InputGroup, InputGroupAddon, InputGroupText, Input } from "reactstrap";
import { ConfigContext } from "../../Context"
import { connection_header } from "../../content"

var moment = require('moment');
const Header = (props) => {

  const { config } = React.useContext(ConfigContext);

  const [fromDate, setFromDate] = useState(moment().format("YYYY-MM-DD"))
  const [phone, setSetPhone] = useState('')
  const [name, setName] = useState('')
  const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"))


  const searchPhone = (e) => {
    if (e.keyCode === 13) {
      props.deepSearch(e.target.value)
    }
  }
  const searchName = (e) => {
    if (e.keyCode === 13) {
      props.nameSearch(e.target.value)
    }
  }

  return (
    <>
      <div className={`header ${props.headerPadding ? props.headerPadding : 'pb-8 pt-5 pt-md-8'}`} style={{ background: config.connectionBgColor }}>
        <Container fluid>
          <div className="header-body">
            {/* Card stats */}
            {props.deepSearch && <>
              <Row>

                <div className="row gy-2 gx-3 align-items-center">
                  <div className="col-auto">
                    <label htmlFor="search" style={{ color: 'white', marginLeft: 10, marginTop: 5 }}><b>{connection_header.label_searchByPhone}</b></label>
                  </div>
                  <div className="col-auto" style={{ marginLeft: 15 }}>

                    <InputGroup className="input-group-alternative" >
                      <InputGroupAddon addonType="prepend" >
                        <InputGroupText>
                          <i className="fas fa-search" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input placeholder={connection_header.textbox_searchByPhone} type="text" style={{ width: 'auto' }}
                        onChange={(e) => setSetPhone(e.target.value)}
                        onKeyDown={(e) => { searchPhone(e) }}
                        value={phone} />
                    </InputGroup>
                  </div>

                  <div className="col-auto" style={{ marginLeft: '13px' }}>
                    <Button
                      color="default"
                      onClick={props.deepSearch.bind(null, phone)}
                      size="l">
                      {connection_header.button_search}
                    </Button>
                  </div>
                </div>
              </Row>
            </>}

            {props.nameSearch &&
              <>
                <hr />

                <Row>

                  <div className="row gy-2 gx-3 align-items-center">
                    <div className="col-auto">
                      <label htmlFor="search" style={{ color: 'white', marginLeft: 10, marginTop: 5 }}><b>{connection_header.label_searchByName}</b></label>
                    </div>
                    <div className="col-auto" style={{ marginLeft: 15 }}>

                      <InputGroup className="input-group-alternative" >
                        <InputGroupAddon addonType="prepend" >
                          <InputGroupText>
                            <i className="fas fa-search" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input placeholder={connection_header.textbox_searchByName} type="text" style={{ width: 'auto' }}
                          onChange={(e) => setName(e.target.value)}
                          onKeyDown={(e) => { searchName(e) }}
                          value={name} />
                      </InputGroup>
                    </div>

                    <div className="col-auto" style={{ marginLeft: '13px' }}>
                      <Button
                        color="default"
                        onClick={props.nameSearch.bind(null, name)}
                        size="l">
                        {connection_header.button_search}
                      </Button>
                    </div>
                  </div>

                </Row>
              </>}

            {props.filterSearch && <>
              <hr />
              <Row>

                <div className="row gy-2 gx-3 align-items-center">
                  <div className="col-auto" style={{ marginTop: 10 }}>
                    <label htmlFor="fromDate" className="col-sm-2 col-form-label" style={{ color: 'white', marginRight: 20 }}><b>{connection_header.label_From}</b></label>
                  </div>
                  <div className="col-auto" style={{ marginTop: 10 }}>
                    <Input placeholder="From" style={{ width: 'auto' }} id="fromDate" type="date" onChange={(e) => setFromDate(e.target.value)}
                      value={fromDate} />
                  </div>
                </div>
                <div className="row gy-2 gx-3 align-items-center">
                  <div className="col-auto" style={{ marginTop: 10 }}>
                    <label htmlFor="toDate" className="col-sm-2 col-form-label" style={{ color: 'white', marginRight: 40 }}><b>{connection_header.label_To} </b></label>
                  </div>

                  <div className="col-auto" style={{ marginTop: 10 }}>
                    <Input placeholder="To" style={{ width: 'auto' }} id="toDate" type="date" onChange={(e) => setToDate(e.target.value)}
                      value={toDate} />
                  </div>

                  <div className="col-auto" style={{ marginLeft: 15, marginTop: 10 }}>
                    <Button
                      color="default"
                      onClick={props.filterSearch.bind(null, fromDate, toDate)}
                      size="l">
                      {connection_header.button_searchByDate}
                    </Button>
                  </div>

                </div>
              </Row>
            </>}
          </div>
        </Container>
      </div>
    </>
  );
};

export default Header;
