import Login from "views/auth/Login.js";
import Users from "views/Users";
import Audit from "views/Audit"
import PERMISSIONS from "components/Permissions/Permissions";
import {
  company_requests, campaign_requests,
  userManagement, audit_requests
} from "content";

var routes = [
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
  },
  {
    path: "/audit-requests",
    name: audit_requests,
    icon: "fas fa-landmark text-orange",
    component: Audit,
    layout: "/admin",
    roles: ["admin"],
    permission: [PERMISSIONS.view_audit_req],
  },
  {
    path: "/users",
    name: userManagement,
    icon: "fa fa-users text-blue",
    component: Users,
    layout: "/admin",
    roles: ["admin"],
    permission: [PERMISSIONS.view_users],
  },

];

export default routes;
