const jwt = require("jsonwebtoken");
const axios = require('axios')

const Auth = {
    authenticate(token, exp) {
        localStorage.setItem('itoken', token);
        localStorage.setItem('exp', exp);
    },

    signout() {
        localStorage.removeItem('itoken');
        localStorage.removeItem('name');
        localStorage.removeItem('image');
        localStorage.removeItem('firstLoad');
        localStorage.removeItem('exp');
        localStorage.removeItem('table');
    },

    getAuth() {
        //TODO: verify token for more security
        try {
            let token = localStorage.getItem('itoken');
            let decodedToken = jwt.decode(token);
            let tokenRole = decodedToken['role'];
            return tokenRole;
        } catch (error) {
            return undefined;
        }
    },

    getAuthPermissions() {
        try {
            let token = localStorage.getItem('itoken');
            let decodedToken = jwt.decode(token);
            let tokenPermssion = decodedToken['permissions'];

            if (!tokenPermssion) {
                return [];
            }

            return tokenPermssion;

        } catch (error) {
            return [];
        }
    },

    getToken() {
        let nowUnixEpoch = Math.round(Date.now() / 1000);
        let expUnixEpoch = Number(localStorage.getItem('exp'));
        if ((expUnixEpoch - nowUnixEpoch) <= 120) {
            return undefined;
        } else {
            return localStorage.getItem('itoken');
        }
    },

    checkAuth() {

        const nowUnixEpoch = Math.round(Date.now() / 1000);
        const expUnixEpoch = Number(localStorage.getItem('exp'));

        const token = this.getToken();

        if (token) {
            // NOTE : We display message 4 mins before session expires and expires session 2 mins before token expirtaion
            // here display message : session ending in 2 mins
            if ((expUnixEpoch - nowUnixEpoch) <= 240) {
                return { displayMessage: true }
            } else {
                return { displayMessage: false, logOut: false }
            }
        } else {
            // session expired
            return { logOut: true }
        }
    },

    refreshToken() {
        const expUnixEpoch = Number(localStorage.getItem('exp'));

        const REFRESH_TOKEN_API_PATH = process.env.REACT_APP_API_URL + `/api/refresh_token?exp=${expUnixEpoch}`

        fetch(REFRESH_TOKEN_API_PATH, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.getToken() }
        }).then(res => res.json())
            .then(async json => {
                if (json['success']) {
                    //TODO: verify token for more security
                    let decodedToken = jwt.decode(json.token);
                    let tokenRole = decodedToken['role'];

                    const resp = await axios.get(`${process.env.REACT_APP_API_URL}/api/roles`, {
                        headers: { 'Authorization': 'Bearer ' + json.token }
                    })

                    let roles = resp.data.roles?.map(e => e.role);

                    if (roles.includes(tokenRole)) {

                        localStorage.setItem('name', decodedToken['name']);
                        localStorage.setItem('image', decodedToken['image']);
                        // localStorage.setItem('admin_center', decodedToken['admin_center']);
                        // localStorage.setItem('tenant', decodedToken['tenant']);
                        localStorage.setItem('subject', decodedToken['subject']);
                        localStorage.setItem('_id', decodedToken['_id'])
                        localStorage.setItem('firstLoad', 'true');
                        Auth.authenticate(json.token, json.exp);


                        window.location.reload();

                    }

                    else {
                        console.error('Unknown user type!');
                    }
                } else {
                    //login unsuccessful error         
                    console.error("token refresh failed")
                }
            });
    }

};

export default Auth;