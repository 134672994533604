export const company_requests = "Company Verification"
export const campaign_requests = "Campaign Verification"
export const audit_requests = "Auditing"
export const userManagement = "User Management"

export const connection_header = {
    label_searchByPhone: "Search by phone:",
    textbox_searchByPhone: "Search by phone#",
    label_searchByName: "Search by Name:",
    textbox_searchByName: "Search by Name",
    button_search: "Search",
    label_From: "From:",
    label_To: "To:",
    button_searchByDate: "Search by Date"
}

export const company_aml_screen = {
    sub_heading: "Search",
    label_company_name: "Company Name:",
    input_placeholder_name: "Search by Name",
    label_email: "Email:",
    input_placeholder_email: "Search by Email",
    label_country: "Country:",
    input_placeholder_country: "Search by Country",
    button_clear: "Clear",
    button_search: "Search",
    cardheader: "Company Requests",
    button_approve_all: "APPROVE ALL",
    button_reject_all: "REJECT ALL",
    navlink_1: "Awaiting Approval",
    navlink_2: "Approved",
    navlink_3: "Rejected",

    table_col_name: "Company Name",
    table_col_type: "Type",
    table_col_country: "Country",
    table_col_incorporated: "Incorporated",
    table_col_email: "Email",
    table_col_request_date: "Request Date",
    table_col_actions: "Actions",

    button_view_detail: "VIEW DETAIL",
    button_approve: "APPROVE",
    button_reject: "REJECT",
    button_revert: "REVERT",
    table_no_request: "NO REQUEST AVAILABLE",
    modal_details_heading: "Company Details",
    button_close: "Close"
}

export const campaign_aml_screen = {
    sub_heading: "Search",
    label_title: "Campaign Title:",
    input_placeholder_title: "Search by Title",
    label_company: "Company Name:",
    input_placeholder_company: "Search by Company Name",
    label_category: "Category:",
    input_placeholder_category: "Select Category",
    button_clear: "Clear",
    button_search: "Search",

    cardheader: "Campaign Requests",
    button_approve_all: "APPROVE ALL",
    button_reject_all: "REJECT ALL",

    navlink_1: "Awaiting Approval",
    navlink_2: "Approved",
    navlink_3: "Rejected",

    table_col_title: "Campaign Title",
    table_col_company: "Company Name",
    table_col_target: "Target Amount",
    table_col_start: "Start Date",
    table_col_end: "End Date",
    table_col_request_date: "Request Date",
    table_col_actions: "Actions",

    button_view_detail: "VIEW DETAIL",
    button_approve: "APPROVE",
    button_reject: "REJECT",
    button_revert: "REVERT",
    table_no_request: "NO REQUEST AVAILABLE",
    modal_details_heading: "Campaign Details",
    modal_details_th_credentialField: "Credential Field",
    modal_details_th_value: "Value",

    button_close: "Close"
}

export const audit_screen = {
    sub_heading: "Search",
    label_company_name: "Company Name:",
    input_placeholder_name: "Search by Name",
    label_email: "Email:",
    input_placeholder_email: "Search by Email",
    label_country: "Country:",
    input_placeholder_country: "Search by Country",
    button_clear: "Clear",
    button_search: "Search",
    cardheader: "Audit Requests",
    button_approve_all: "APPROVE ALL",
    button_reject_all: "REJECT ALL",
    navlink_1: "Awaiting Approval",
    navlink_2: "Conflicts",
    navlink_3: "Rejected",
    table_col_name: "Company Name",
    table_col_type: "Type",
    table_col_country: "Country",
    table_col_incorporated: "Incorporated",
    table_col_email: "Email",
    table_col_request_date: "Request Date",
    table_col_document: "Document",
    table_col_actions: "Actions",
    button_view_detail: "VIEW DETAIL",
    button_approve: "APPROVE",
    button_reject: "REJECT",
    button_revert: "REVERT",
    table_no_request: "NO REQUEST AVAILABLE",
    modal_details_heading: "Disclosure Details",
    modal_rejection_heading: "Reject Audit Request Confirmation",
    button_close: "Close",
    modal_form_label_reason: "Reason",
}

export const userManagement_screen = {
    sub_heading: "User Board",
    button_add_new_user: "ADD NEW USER",
    heading_col_username: "Username",
    heading_col_role: "Role",
    heading_col_created_date: "Created Date",
    modal_addUser_heading_1: "Edit User",
    modal_addUser_heading_2: "Add New User",
    modal_form_label_fullName: "Full Name",
    modal_form_label_username: "Username",
    modal_form_input_help: "Only Alphabets, Numbers, Underscore and characters between 4 to 10.",
    modal_form_label_phone: "Phone",
    modal_form_label_role: "Role",
    modal_form_label_select_role: "Select Role",
    modal_form_label_country: "Country",
    modal_form_label_select_country: "Select Country",
    modal_form_label_permissions: "Permissions",
    modal_form_label_imageURL: "Image URL",
    modal_form_label_password: "Password",
    modal_form_alert_message: "Password Successfully Copied!",
    modal_form_label_less_password: "Make User Password Less",
    button_cancel: "Cancel",
    button_update: "Update",
    button_add: "Add",
    button_continue: "Continue",
    confirmationModal_deleteUser_message: "Are you sure you want to delete this user! Becuase this action cannot be undone."
}

export const shared_info = {
    modal_contactus_heading: "Contact Us",
    modal_contactus_phone_label: "Phone : ",
    modal_contactus_phone_value: "+46736645276",
    modal_contactus_email_label: "Email : ",
    modal_contactus_email_value: "support@omaxx.io",
    button_close: "Close",
    welcome_text: "Welcome!",
    logout_text: "Logout"
}